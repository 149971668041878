<template>
    <Modal title="排序設定" v-model="isShowModal">
        <template v-slot:modal-content>
            <div class="hint">目前排序: {{ sortLabel }}</div>
            <div class="table-sort-container">
                <Dropdown
                    title="排序欄位"
                    name="sortBy"
                    placeholder="請選擇排序欄位"
                    :options="sortByOptions"
                    v-model="sortByValue"
                />
                <div class="hr" />
                <Dropdown
                    title="排序方法"
                    name="sort"
                    :disabled="!sortByValue"
                    :options="dropdownSortOptions"
                    v-model="sortValue"
                />
            </div>
        </template>
        <template v-slot:modal-bottom>
            <div class="to-center button-container">
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click.prevent="cancel()"
                />
                <Button
                    buttonWord="確定"
                    buttonStyle="blue"
                    @click.prevent="apply()"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: 'TableSortModal',
    emits: ['update:modelValue', 'update:sortBy', 'update:sort'],
    components: {
        Dropdown,
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        sortOptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        sortByOptions: {
            type: Array,
            default: function () {
                return []
            }
        },
        sort: {
            type: String,
            default: ''
        },
        sortBy: {
            type: String,
            default: ''
        },
        sortLabel: {
            type: String,
            default: ''
        }
    },
    methods: {
        cancel: function () {
            this.isShowModal = false
        },
        apply: function () {
            this.$emit('update:sort', this.sortValue)
            this.$emit('update:sortBy', this.sortByValue)
            const sort =
                this.sortValue[0].toUpperCase() + this.sortValue.slice(1)
            this.$setGaEvent(`${this.sortByValue}${sort}`, 'click-Sort')
            this.isShowModal = false
        }
    },
    computed: {
        isShowModal: {
            get: function () {
                return this.modelValue
            },
            set: function (val) {
                this.$emit('update:modelValue', val)
            }
        },
        dropdownSortOptions: function () {
            return this.sortOptions.filter((option) => {
                if (this.sortByValue) return option.value !== 'normal'
                else return option.value === 'normal'
            })
        }
    },
    watch: {
        sort() {
            this.sortValue = this.sort
        },
        sortBy() {
            this.sortByValue = this.sortBy
        }
    },
    data() {
        return {
            sortValue: this.sort,
            sortByValue: this.sortBy
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    font-weight: normal;
    text-align: left;

    :deep(.modal-content) {
        height: 330px;

        .input-container {
            width: 100%;
        }
    }
}

.hr {
    margin: 40px 0;
}

.hint {
    color: $sixth-black;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.filter-icon {
    display: none;
    align-items: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    @media screen and (max-width: 576px) {
        display: block;
    }
}
:deep(.input-container) {
    margin-bottom: 10px;
}

:deep(.option-container) {
    z-index: 5;
}
</style>
